<template>
  <div>
    <vx-card class="mb-4">
      <form-detail-include :model="model" :id="id"></form-detail-include>
      <div class="w-full flex gap-2 justify-end print:hidden">
          <vs-button
            class="float-right"
            color="primary"
            type="border"
            @click="goback">{{ $t('action.back') }}</vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'

import AppealService from '@/services/api/AppealService'
import FormDetailInclude from './FormDetailInclude.vue'


export default {
  directives: {

  },
  components: {
    FormDetailInclude

  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    model: {
      id: null,
      reason : '',
      reasoning: '',
      protocol_number: '',
      model_id: null,
      model_type: null,
      model_name: null,
    }
  }),
  computed: {

  },
  beforeMount() {
    this.service = AppealService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model = response
        },
        error => {this.$vs.loading.close()}
      ).finally(() => {this.$vs.loading.close()})
    },
    goback() {
      this.$router.push('/appeals')
    },
    isAdmin() {
      return isAdmin()
    },
  }
}
</script>

<style>

</style>
